import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.member',
      search: 'member.search_keyword',
      filter: {
        locked: {
          label: 'member.data.locked',
          type: 'selection',
          options: [false, true],
          optionTextPrefix: 'member.data.locked',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        email: row.email,
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      exportExcel: {
        filename: (time: string) => `${this.vm?.$t('module.member')}`,
        sheets: [
          {
            label: 'module.member',
            data: {
              id: { label: 'ID', width: 20 },
              name: { label: 'data.person_name', width: 18 },
              email: { label: 'data.email', width: 26 },
              phone: { label: 'data.phone', width: 22 },
              avatar: {
                label: 'data.avatar',
                value: row => this.vm?.$helper.getPhotoUrl(row.avatar, ['origin']),
              },
              register_type: {
                label: 'member.data.register_type',
                width: 22,
                value: row => this.vm?.$t(`sso.${row.register_type}`),
              },
              birthday: {
                label: 'member.data.birthday',
                width: 22,
                value: row => window.eagleLodash.get(row, ['info', 'birthday']),
              },
              locked: {
                label: 'member.data.locked',
                value: row => this.vm?.$t(`data.${!!row.locked}`),
                width: 12,
              },
              created_at: { label: 'data.created_at', width: 18 },
            },
          },
        ]
      },
      create: {
        popupComponent: () => import('@/modules/member/views/memberList/memberListCreatePopup.vue'),
        disabledApply: (formData) => {
          if(window.helper.textEmpty(formData.email)) return true
          if(!window.helper.validator.minText(formData.name, 2)) return true
          if(!window.helper.validator.email(formData.email)) return true
          if(!window.helper.validator.minText(formData.password, 2)) return true
          if(!window.helper.validator.minText(formData.password_confirm, 2)) return true
          if(formData.password != formData.password_confirm) return true
          return false
        },
        createApi: async (formData) => await this.vm?.$api.collection.memberApi.create(formData),
        redirect: (result => ({
          name: `member-update`,
          params: { target: result.id },
        }))
      },
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'member',
            label: 'module.member',
            text: row => this.vm?.$helper.getSiteUserName(row),
            copyable: true,
          },
          {
            key: 'email',
            label: 'data.email',
            type: 'text',
            copyable: true,
          },
          {
            key: 'register_type',
            label: 'member.data.register_type',
            type: 'text',
            text: row => this.vm?.$t(`sso.${row.register_type}`),
          },
          {
            key: 'locked',
            label: 'member.data.locked',
            type: 'switch',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'member-update', params: { target: row.id } }),
          linkTarget: '_self',
        },
        delete: {
          removeTarget: row => row.name,
          removeConfirmContent: row => `${this.vm?.$t(`member.delete.confirm`)} ${row.email}`,
          confirmIdentity: true,
        },
        forceResetMemberPassword: {
          component: () => import('./memberList/forceResetMemberPassword.vue'),
        },
        login: {
          component: () => import('./memberList/loginAsMember.vue'),
        },
      },
    }

  }
}

export default new listConfig()
